import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Finkenberg: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Finkenberg,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Finkenberg"
          points="814,812 826,836 802,838 798,822 802,782 822,804"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 793.5066 809.4803)"
        >
          <tspan x="0" y="0">
            Finken-
          </tspan>
          <tspan x="0" y="14.4">
            berg
          </tspan>
        </text>
      </g>
    </>
  );
});
